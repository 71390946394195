// :cow: Cosmose CONFIDENTIAL :iso:
export interface ContentfulConfig {
  accessToken: string;
  space: string;
  host: string;
  regionEntryId: string;
}

interface AvailableLanguage {
  name: Language,
  value: any;
}

export interface EnvironmentModel {
  production: boolean;
  gtmId: string;
  languages: AvailableLanguage[];
  onlyMerchantForm: boolean;
  appleUrl: string;
  googleUrl: string;
  pageUrl: string;
  defaultPrefix: string;
  localeId: string;
}

export enum Language {
  en_SG = 'en_SG',
  zh_HK = 'zh_HK',
  en_US = 'en_US',
  en_JP = 'en_JP',
  ja_JP = 'ja_JP',
}
