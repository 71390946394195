// :cow: Cosmose CONFIDENTIAL :iso:
import { config as kaikaiSgProd } from '@kai/core/config/configs/sg/sg.kaikai.ai';
import { config as kaikaiSgPreprod } from '@kai/core/config/configs/sg/sg.kaikai.preprod.cosmose.cloud';
import { config as kaikaiSgDev } from '@kai/core/config/configs/sg/sg.kaikai.dev.cosmose.cloud';

import { config as kaikaiHkProd } from '@kai/core/config/configs/hk/hk.kaikai.ai';
import { config as kaikaiHkPreprod } from '@kai/core/config/configs/hk/hk.kaikai.preprod.cosmose.cloud';
import { config as kaikaiHkDev } from '@kai/core/config/configs/hk/hk.kaikai.dev.cosmose.cloud';

import { config as kaikaiUsProd } from '@kai/core/config/configs/us/us.kaikai.ai';
import { config as kaikaiUsPreprod } from '@kai/core/config/configs/us/us.kaikai.preprod.cosmose.cloud';
import { config as kaikaiUsDev } from '@kai/core/config/configs/us/us.kaikai.dev.cosmose.cloud';

import { config as kaikaiJpProd } from '@kai/core/config/configs/jp/jp.kaikai.ai';
import { config as kaikaiJpPreprod } from '@kai/core/config/configs/jp/jp.kaikai.preprod.cosmose.cloud';
import { config as kaikaiJpDev } from '@kai/core/config/configs/jp/jp.kaikai.dev.cosmose.cloud';
import { EnvironmentConfig } from './model/environment.config';

export const getConfig = (domain: string): EnvironmentConfig => {
  const configMap = {
    'kaikai.ai': kaikaiSgProd,
    'kaikai.preprod.cosmose.cloud': kaikaiSgPreprod,
    'kaikai.dev.cosmose.cloud': kaikaiSgDev,

    'hk.kaikai.ai': kaikaiHkProd,
    'hk.kaikai.preprod.cosmose.cloud': kaikaiHkPreprod,
    'hk.kaikai.dev.cosmose.cloud': kaikaiHkDev,

    'us.kaikai.ai': kaikaiUsProd,
    'us.kaikai.preprod.cosmose.cloud': kaikaiUsPreprod,
    'us.kaikai.dev.cosmose.cloud': kaikaiUsDev,

    'jp.kaikai.ai': kaikaiJpProd,
    'jp.kaikai.preprod.cosmose.cloud': kaikaiJpPreprod,
    'jp.kaikai.dev.cosmose.cloud': kaikaiJpDev,

    'localhost:4200': kaikaiSgDev,
  };

  return configMap[domain] ?? kaikaiSgProd;
};
