// :cow: Cosmose CONFIDENTIAL :iso:
import { EnvironmentConfig } from '../../model/environment.config';

export const config: EnvironmentConfig = {
  production: true,
  apiRoot: 'https://deal-hunter-registration-hk.sg-preprod.cosmose.cloud/deal-hunter-registration-api/v1',
  dhApiRoot: 'https://dealhunter.hk.cosmose.co',
  ppApiRoot: 'https://api.sg.cosmose.co/deal-hunter-partner-api/v1',
  smartLookIntegration: false,
  contentful: {
    accessToken: 'nJ6gXFfV3meRAqTT98eU0p2vJWo4KfWPAr4nZRfRk7c',
    space: 'bt9hwdef88x8',
    host: 'cdn.contentful.com',
    regionEntryId: '11mnTeOCqEOl2OXHjteYrF',
  },
};
