// :cow: Cosmose CONFIDENTIAL :iso:
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: (): Promise<any> => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'merchant-signup',
    loadChildren: (): Promise<any> => import('./modules/merchant-signup-host/merchant-signup-host.module').then(m => m.MerchantSignupHostModule),
  },
  {
    path: 'click-and-collect',
    loadChildren: (): Promise<any> => import('./modules/click-and-collect/click-and-collect.module').then(m => m.ClickAndCollectModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'top',
    onSameUrlNavigation: 'reload'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
