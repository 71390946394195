// :cow: Cosmose CONFIDENTIAL :iso:
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@kai/core/config/config.injection-token';
import { EnvironmentConfig } from '@kai/core/config/model/environment.config';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  constructor(
    @Inject(APP_CONFIG) private config,
  ) {
  }

  getConfig(): EnvironmentConfig {
    return this.config;
  }
}
