// :cow: Cosmose CONFIDENTIAL :iso:
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import localeZh from '@angular/common/locales/zh-Hans-HK';
import localeSg from '@angular/common/locales/en-SG';
import localeJp from '@angular/common/locales/ja';
import localeUs from '@angular/common/locales/en';

import { registerLocaleData } from '@angular/common';
import { ClickAndCollectInterceptor } from '@kai/modules/click-and-collect/core/interceptors/click-and-collect.interceptor';
import { environment } from '../environments/environment';

registerLocaleData(localeZh);
registerLocaleData(localeSg);
registerLocaleData(localeJp);
registerLocaleData(localeUs);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.languages[0].name,
      extend: true,
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    StoreModule.forRoot({}, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    MatSliderModule,
    NgxPageScrollCoreModule.forRoot(),
    GoogleTagManagerModule.forRoot({
      id: environment.gtmId,
    }),
  ],
  providers: [
    Title,
    [{provide: LOCALE_ID, useValue: environment.localeId}],
    {provide: HTTP_INTERCEPTORS, useClass: ClickAndCollectInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
