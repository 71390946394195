// :cow: Cosmose CONFIDENTIAL :iso:
import { EnvironmentConfig } from '../../model/environment.config';

export const config: EnvironmentConfig = {
  production: true,
  apiRoot: 'https://kaikai-registration-hk.dev.cosmose.cloud/deal-hunter-registration-api/v1',
  dhApiRoot: 'https://deal-hunter.dev.cosmose.cloud',
  ppApiRoot: 'https://api.dev.cosmose.cloud/deal-hunter-partner-api/v1',
  smartLookIntegration: false,
  contentful: {
    accessToken: '33Fv7dYNmGOFDo_GXD-KKOzS-K8gNOTCFdxLIWyXYfM',
    space: 'bt9hwdef88x8',
    host: 'preview.contentful.com',
    regionEntryId: '11mnTeOCqEOl2OXHjteYrF',
  },
};
