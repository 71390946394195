// :cow: Cosmose CONFIDENTIAL :iso:
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { getConfig } from '@kai/core/config/get-config';
import { environment } from './environments/environment';
import { APP_CONFIG } from '@kai/core/config/config.injection-token';

const config = getConfig(document.location.host);
Object.freeze(config);
if (environment.production) {
  enableProdMode();
}

if (config.smartLookIntegration) {
  enableSmartLookIntegration();
}

platformBrowserDynamic([{provide: APP_CONFIG, useValue: config}])
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

function enableSmartLookIntegration(): void {
  const scriptElement = document.createElement('script');
  scriptElement.type = 'text/javascript';
  scriptElement.innerHTML =
    'window.smartlook||(function(d) {\n' +
    '      var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName(\'head\')[0];\n' +
    '      var c=d.createElement(\'script\');o.api=new Array();c.async=true;c.type=\'text/javascript\';\n' +
    '      c.charset=\'utf-8\';c.src=\'https://rec.smartlook.com/recorder.js\';h.appendChild(c);\n' +
    '    })(document);\n' +
    '    smartlook(\'init\', \'e9c504596b18b4363335810cd189b07b36815d8c\');\n';
  document.getElementsByTagName('head')[0].appendChild(scriptElement);
}
